import React from "react";

import curfew from "../assets/workedwith/10pm-curfew.png";
import fittrack from "../assets/workedwith/fittrack.png";
import glamnetic from "../assets/workedwith/glamnetic.png";
import underlining from "../assets/workedwith//underlining.png";
import tatbrow from "../assets/workedwith/tatbrow.png";
import inh from "../assets/workedwith/insert-name-here.png";
import vello from "../assets/workedwith/vello.png";
import sp from "../assets/workedwith/spa-sciences.png";
import jill from "../assets/workedwith/jill.png";
import hide from "../assets/workedwith/hide.png";
import michael from "../assets/workedwith/michael-todd.png";
import nailboo from "../assets/workedwith/nailboo.png";
import pagerie from "../assets/workedwith/pagerie.png";
import habit from "../assets/workedwith/habit.png";
import homebody from "../assets/workedwith/homebody.png";
import imperial from "../assets/workedwith/imperial-candles.png";
import celine from "../assets/workedwith/celine-victor.png";
import fuego from "../assets/workedwith/fuego.png";
import kreyol from "../assets/workedwith/kreyol-essence.png";
import nood from "../assets/workedwith/nood.png";

const Companies = () => {
  return (
    <div className="col-10 col-lg-8 mx-auto">
      <div className="company-heading t-a-c mt-5">I’ve worked with</div>
      <div className="mt-5 space-bt pb-1">
        <img src={nailboo} alt="" className="img-company" />
        <img src={underlining} alt="" className="img-company" />
        <img src={nood} alt="" className="img-company" />
        <img src={glamnetic} alt="" className="img-company" />
        <img src={hide} alt="" className="img-company" />
        <img src={pagerie} alt="" className="img-company" />
        <img src={fittrack} alt="" className="img-company" />
        <img src={tatbrow} alt="" className="img-company" />
        <img src={sp} alt="" className="img-company" />
        <img src={michael} alt="" className="img-company" />
        <img src={habit} alt="" className="img-company" />
        <img src={vello} alt="" className="img-company" />
        <img src={homebody} alt="" className="img-company" />
        <img src={celine} alt="" className="img-company" />
        <img src={inh} alt="" className="img-company" />
        <img src={imperial} alt="" className="img-company" />
        <img src={curfew} alt="" className="img-company" />
        <img src={jill} alt="" className="img-company" />
        <img src={kreyol} alt="" className="img-company" />
        <img src={fuego} alt="" className="img-company" />
      </div>
    </div>
  );
};

export default Companies;
