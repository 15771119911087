import React from "react";
import Carousel from "../components/Carousel";
import Companies from "../components/Companies";

import purple from "../assets/purple.svg";
import gray from "../assets/gray.svg";

const Landing = () => {
  return (
    <>
      <div className="container-fluid center min-h-100 py-5 p-rel">
        <div className="col-10 col-lg-8 mx-auto">
          <div className="h2 t-a-c">Hi, I'm Nitish Kumar</div>
          <div className="h1 t-a-c mt-4">
            Your Partner for Scaling <br /> through Social Advertising.
          </div>
          <div className="content t-a-c my-4">
            I’m a media buyer dedicated to helping you achieve and exceed your
            paid ad revenue goals! From $100 to $100,000 of daily ad spend, I've
            helped companies scale quickly with social ad execution,
            high-performing ad creatives, and customized media buying
            strategies. 
          </div>
          <div className="center mt-5">
            <a
              href="https://calendly.com/nitishsdhiman"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="button-landing">
                Book Your Free Consultation
              </button>
            </a>
          </div>
        </div>
        <ul className="circles">
          <li>
            <img src={purple} alt="" className="img-fluid" />
          </li>
          <li>
            <img src={gray} alt="" className="img-fluid" />
          </li>
          <li>
            <img src={purple} alt="" className="img-fluid" />
          </li>
          <li>
            <img src={gray} alt="" className="img-fluid" />
          </li>
        </ul>
      </div>
      <div className="bg-white container-fluid">
        <div className="center">
          <Companies />
        </div>
        <div className="center">
          <Carousel />
        </div>
      </div>
      <div className="container-fluid center min-h-100 py-5 p-rel">
        <div className="col-10 col-lg-8 mx-auto">
          <div className="h1 t-a-c mt-4">
            Ready to boost your revenue <br /> through strategic paid ads <br />{" "}
            on social media? 
          </div>
          <div className="center mt-5">
            <a
              href="https://calendly.com/nitishsdhiman"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="button-landing">
                Book Your Free Consultation
              </button>
            </a>
          </div>
        </div>
        <ul className="circles">
          <li>
            <img src={purple} alt="" className="img-fluid" />
          </li>
          <li>
            <img src={gray} alt="" className="img-fluid" />
          </li>
          <li>
            <img src={purple} alt="" className="img-fluid" />
          </li>
          <li>
            <img src={gray} alt="" className="img-fluid" />
          </li>
        </ul>
      </div>
    </>
  );
};

export default Landing;
