import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import adam from "../assets/testimonails/adam.png";
import ann from "../assets/testimonails/ann.png";
import kevin from "../assets/testimonails/kevin.png";
import kishawn from "../assets/testimonails/kishawn.png";
import mandy from "../assets/testimonails/mandy.png";
import meagan from "../assets/testimonails/meagan.png";
import razvan from "../assets/testimonails/razvan.png";

const Carousel = () => {
  const carouselItems = [
    {
      name: "Razvan Romanescu",
      company: "Founder & CEO Underlining, Nailboo",
      image: razvan,
      testimonial:
        "“Nitish is someone I consider a friend and long term business partner. He is truly one of the few individuals out there that actually speaks the same language and understands how to effectively scale a brands paid media efforts.There is no fluff or smoke and mirrors with him and he is one of the most committed and outstanding individuals I’ve had the pleasure to work with in my life. Has my co-sign !”",
    },
    {
      name: "Kevin Gould",
      company: "Co-founder of Glamnetic and INH Hair",
      image: kevin,
      testimonial:
        "“Nitish has been with Glamnetic and INH since the very beginning and he has been an amazing team member. We launched our paid media strategy together and quickly scaled over the last two years and Nitish worked extremely hard. It was a pleasure to work with him and also now call him a friend. I recommend Nitish for anyone looking for a very reliable paid media buyer.”",
    },
    {
      name: "Ann McFerran",
      company: "Founder/CEO at GLAMNETIC",
      image: ann,
      testimonial:
        "“Nitish is an awesome ad buyer and has been with us from day 1 and its been a pleasure to work with him. He’s super passionate about his work and it shows. I would recommend him to anyone out there getting started in ad buying!”",
    },
    {
      name: "Adam Wellington",
      company: "CEO & Co-Founder at Neon Flux",
      image: adam,
      testimonial:
        "“Nitish is a standout media buyer with incredible direct-to-consumer brand knowledge. He has a talent for turning goals into effective, growth-driven strategies on Facebook Ads. Equally impressive is his natural collaboration skills, always eager to share insights and foster innovation within the team. His passion for his work is truly inspiring. I recommend Nitish without hesitation for any media buying or digital advertising roles, as his strategic prowess, deep understanding of Facebook Ads, and team-oriented mindset would be an invaluable asset.”",
    },
    {
      name: "Meagan Carboney",
      company: "Founder and Chief Brand Officer at Jill Razor",
      image: meagan,
      testimonial:
        "“Working with Nitish has been a true partnership! The way he has cared for the brand and it’s performance is as if it was his own! As a start up, finding the right people at the right time can be make it or break it moments and Nitish was the right person to come in and help us scale the brand through paid across multiple platforms. He knows them all and he knows them well! Nitish doesn’t just bring experience and expertise though — he brings ideas and will always push us to do better and be better. Highly recommend Nitish if you’re looking to scale your brand!”",
    },
    {
      name: "Kishawn Meeranazeer",
      company: "Co-Founder & CEO at Jill Razor",
      image: kishawn,
      testimonial:
        "“Nitish has been an incredibly valuable team member at Jill. He’s helped us navigate the waters of all things paid and brought immediate action and results. He was able to scale our acquisitions and growth by 100% QoQ while maintaining a stable target CPA. He’s a true professional that relentlessly works to achieve the goals of not just paid but the entire brand. I would highly recommend Nitish to anyone with media buying needs!”",
    },
    {
      name: "Mandy Madden Kelley",
      company: "Founder & CEO at PAGERIE",
      image: mandy,
      testimonial:
        "“As a business owner, it can be difficult to navigate the world of marketing and advertising. Not only do you have to come up with creative and engaging strategies to reach your target audience, but you also have to keep an eye on budgets and ROI. That's why with so many options available these days, it was pivotal for us to partner with an expert in the field, such as Nitish. He is an experienced advertising professional, who can help you to come up with effective strategies and deliver solid results. He is also an incredible team player, passionate about the brands he works with. Whether you're just getting started or looking for new ideas, partnering with Nitish can take your marketing efforts to the next level.”",
    },
  ];
  return (
    <div className="col-10 col-lg-8 mx-auto bg-white mt-4 mb-5">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        navigation={true}
        loop={true}
        modules={[Autoplay, Navigation, Pagination]}
        className="mySwiper"
      >
        {carouselItems.map((ele, index) => (
          <SwiperSlide key={index}>
            <div className="row py-5">
              <div className="col-md-3 my-auto">
                <div className="col-6 col-md-12 mx-auto p-0">
                  <img src={ele.image} alt="" className="img-fluid img-test" />
                </div>
              </div>
              <div className="col-md-8 px-4 my-auto">
                <div className="test-content mt-3">{ele.testimonial}</div>
                <div className="mt-3 test-company">
                  <span className="test-name">{ele.name}</span>, {ele.company}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
